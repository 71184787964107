<template>
  <div>
    <div class="nurseOffice_tabs">
      <el-card class="box-card">
        <el-collapse v-model="activeName" class="cal__room_cal" accordion>
          <el-collapse-item name="1">
            <template slot="title">
              {{ $t("message.filter") }}
              <!-- <i class="header-icon el-icon-info"></i> -->
            </template>
            <el-form ref="form" :model="form" class="aticler_m" size="small">
              <el-row :gutter="15">
                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.status_0')">
                    <el-select
                      filterable
                      clearable
                      :placeholder="eventColumns.color.title"
                      size="mini"
                      v-model="filterForm3.color"
                    >
                      <el-option
                        :label="$t('message.busy')"
                        value="#66c33a"
                      ></el-option>
                      <el-option
                        :label="$t('message.free')"
                        value="free"
                      ></el-option>
                      <el-option
                        :label="$t('message.booked')"
                        value="#fff385"
                      ></el-option>
                      <el-option
                        :label="$t('message.quarantine')"
                        value="#cc0000"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.class')">
                    <el-select
                      filterable
                      clearable
                      :placeholder="columns.class_id.title"
                      size="mini"
                      v-model="filterForm3.classs_id"
                    >
                      <el-option
                        v-for="item in classses"
                        :key="item.name + item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.corps')">
                    <el-select
                      filterable
                      clearable
                      :placeholder="columns.corps_id.title"
                      size="mini"
                      v-model="filterForm3.corps_id"
                    >
                      <el-option
                        v-for="item in corpses"
                        :key="item.name + item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.floor')">
                    <el-select
                      filterable
                      clearable
                      :placeholder="columns.floor_id.title"
                      size="mini"
                      v-model="filterForm3.floor_id"
                    >
                      <el-option
                        v-for="item in floors"
                        :key="item.name + item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.room')">
                    <el-select
                      filterable
                      clearable
                      :placeholder="columns.hospitalRoom.title"
                      size="mini"
                      v-model="filterForm3.id"
                    >
                      <el-option
                        v-for="item in hospitalRooms"
                        :key="item.name + item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.start_date')">
                    <el-date-picker
                      type="date"
                      :placeholder="$t('message.start_date')"
                      v-model="filterForm3.from"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-form-item :label="$t('message.end_date')">
                    <el-date-picker
                      type="date"
                      :placeholder="$t('message.end_date')"
                      v-model="filterForm3.till"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                      style="width: 100%"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <!-- end col -->

                <el-col :xs="6" :sm="6" :md="3" :lg="3">
                  <el-button
                    class="butt_sacrch"
                    type="success"
                    icon="el-icon-search"
                    size="medium"
                    round
                    style="width: 100%"
                    @click="search()"
                    >{{ $t("message.search_0") }}</el-button
                  >
                </el-col>
              </el-row>
              <!-- end el-row -->
            </el-form>
            <div class="color_cell">
              <div class="itme_color_cell">
                <span class="span3"></span>
                <span class="span_text">{{ $t("message.busy") }}</span>
              </div>
              <div class="itme_color_cell">
                <span class="span2"></span>
                <span class="span_text">{{ $t("message.free") }}</span>
              </div>
              <div class="itme_color_cell">
                <span class="span4"></span>
                <span class="span_text">{{ $t("message.booked") }}</span>
              </div>
              <div class="itme_color_cell">
                <span class="span1"></span>
                <span class="span_text">{{ $t("message.quarantine") }}</span>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
export default {
  mixins: [list],
  data() {
    return {
      loadingData: false,
      filterForm3: {},
      filterForm2: {},
      form: {
        region1112: "shanghai",
        region1: "shanghai",
        region2: "shanghai",
        region3: "shanghai",
        name1: "Все",
        status: "status1",
      },
      activeName: "",
    };
  },
  computed: {
    ...mapGetters({
      events: "schedulesCabinets/list",
      list: "hospitalRooms/dataroom",
      columns: "hospitalRooms/columns",
      eventColumns: "schedulesCabinets/columns",
      pagination: "hospitalRooms/pagination",
      filter: "hospitalRooms/filter",
      eventfilter: "schedulesCabinets/filter",
      sort: "hospitalRooms/sort",
      corpses: "corpses/list",
      floors: "floors/list",
      classses: "classses/list",
      hospitalRooms: "hospitalRooms/list",
    }),

    actions: function () {
      return ["edit", "delete"];
    },
  },

  async mounted() {
    if (this.corpses && this.corpses.length === 0) await this.loadCorpses();
    if (this.floors && this.floors.length === 0) await this.loadFloors();
    if (this.classses && this.classses.length === 0) await this.loadClassses();
    if (this.hospitalRooms && this.hospitalRooms.length === 0)
      await this.loadHospitalRooms();
    if (this.events && this.events.length === 0) await this.updateList();
  },

  methods: {
    ...mapActions({
      updateList: "schedulesCabinets/index",
      updateDataroom: "hospitalRooms/indexbunk",
      updateSort: "hospitalRooms/updateSort",
      updateFilter: "hospitalRooms/updateFilter",
      updateFilterEvent: "schedulesCabinets/updateFilter",
      updateColumn: "hospitalRooms/updateColumn",
      updatePagination: "hospitalRooms/updatePagination",
      updatePaginationEvent: "schedulesCabinets/updatePagination",
      refreshData: "hospitalRooms/refreshData",
      loadCorpses: "corpses/index",
      loadFloors: "floors/index",
      loadClassses: "classses/index",
      loadHospitalRooms: "hospitalRooms/index",
    }),

    search() {
      this.updateList(this.filterForm2);
      this.updateDataroom(this.filterForm3);
    },
    fetchData() {
      const query = { ...this.filter, ...this.pagination, ...this.sort };
      if (!this.loadingData) {
        this.loadingData = true;
        this.updateDataroom(query)
          .then((res) => {
            this.loadingData = false;
          })
          .catch((err) => {
            this.loadingData = false;
          });
      }
    },

    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm3 = JSON.parse(JSON.stringify(this.filter));
          this.filterForm2 = JSON.parse(JSON.stringify(this.eventfilter));
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss">
/******** add click modal height ***********/

/********************************************/

.crm-schedule-header {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: #1f75a8;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 18px;
}

.nurseOffice_tabs .el-card__body {
  padding: 0px;
}
.butt_sacrch {
  margin-top: 20px;
  width: 150px;
  height: 30px;
}

.nurseOffice_tabs .el-card,
.nurseOffice_tabs .el-message {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.nurseOffice_tabs .el-collapse-item__header,
.nurseOffice_tabs .el-collapse,
.nurseOffice_tabs .el-collapse-item__wrap {
  border: none;
}

.busy {
  background-color: #ff000030;
  border-right-color: #ff0000b8 !important;
}

.reserved {
  border-left-color: #ffd3003b !important;
  // border-right-color: #ffd30000 !important;
  background-color: #ffd3003b;
  border-right-color: #ffd3008a !important;
}

.add_cvadrat i {
  background: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  height: 18px;
  width: 18px;
  font-size: 12px;
  display: FLEX;
  justify-content: center;
  align-items: center;
}

.el-icon-arrow-left {
  margin-right: 5px;
}

.add_cvadrat i:hover {
  background: #ffd9d9;
}

.info_roomm {
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
}
.color_cell {
  display: flex;
  margin-top: -20px;
  margin-left: 20px;
  margin-bottom: 10px;
}
.itme_color_cell {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.span1,
.span2,
.span3,
.span4 {
  width: 15px;
  height: 15px;
  display: inline-block;
  border: 1px solid #dee2e6;
  border-radius: 3px;
  margin-right: 3px;
}
.span1 {
  border: 1px solid #ff000030 !important;
  background-color: #ff000070;
}
.span2 {
  border: 1px solid #dee2e6;
}
.span3 {
  border: 1px solid #66c33a78;
  background-color: #66c33a78;
}
.span4 {
  border: 1px solid #fffdea;
  background-color: #fff385a6;
}
</style>